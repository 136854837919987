<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
		fullscreen
	>
		<div class="full_dialog_header">
			<h2 class="tit">{{ options.statName }}</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<div class="dialog_all_page pa-6" style="height: calc(100vh - 50px);">
			<div class="infor_area">
				<div class="tag">{{ options.custType }}</div>
				<h3 class="name" v-if="options.custType === '개인사업자'">
					<!-- 개인사업자 인증전 : 고객명, 인증 후 사업자명 -->
					<template v-if="options.stat.info.customer.bizName">
						{{ options.stat.info.customer.bizName }}
					</template>
					<templete v-else>
						{{ options.stat.info.customer.name }}
					</templete>
				</h3>
				<h3 class="name" v-else>{{ options.name }}</h3>
				<!--// 개인사업자 인증전 : 고객명, 인증 후 사업자명 -->
				<div class="flex">
					<p class="data">
						<span class="primary--text">{{ options.sellType }}</span>
						<span>{{ options.goodsNm }}</span>
						<template
							v-if="
								options.stat !== null &&
									options.stat.info.prdList &&
									options.stat.info.prdList &&
									getProductListCnt(options.stat.info.prdList) !== 0
							"
						>
							외 {{ getProductListCnt(options.stat.info.prdList) }}건
						</template>
					</p>
				</div>
				<div class="flex">
					<p class="text_gray">{{ options.address }}</p>
				</div>
				<p class="text_gray">{{ getPhone(options.mob) }}</p>
			</div>
			<template v-for="(order, i) in orderList">
				<div
					class="bottom_diver"
					:key="i"
					v-if="
						deliveryInfos &&
							(options.stat.statusCode !== '111' ||
								deliveryInfos[i].workerType !== 'EXPRESS')
					"
				>
					<div class="wrap_big_check expand">
						<span class="add_index"
							>주문<em>{{ i + 1 }}</em
							>.</span
						>
						<span
							:class="
								canceledOrderChk(i) === false
									? 'add_label ft-orange'
									: 'add_label'
							"
							>{{ order.GOODS_NM ? order.GOODS_NM : deliveryInfos[i].prodNm }}
							{{
								getPrdName(order.PRODH9) === '기타'
									? getPrdName(deliveryInfos[i].PRODH9)
									: getPrdName(order.PRODH9)
							}}
						</span>
					</div>
					<div class="info_list bor_gray py-3 my-3">
						<dl>
							<dt>주문번호</dt>
							<dd>{{ order.ORDER_NO }}</dd>
						</dl>
						<dl>
							<dt>구매방식</dt>
							<dd>{{ order.E_SALE_GUBUN }}</dd>
						</dl>
						<dl v-if="order.GOODS_AMT > 0 && canceledOrderChk(i)">
							<dt>수납정보</dt>
							<dd>
								{{ order.GOODS_AMT | comma }} 원<br />{{
									order.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
										? order.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
												.CARDCOM_NM
										: ''
								}}
								{{
									getMaskedAccNo(
										order.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
											? order.T_A0401_PAYMENT_LIST.filter(
													x => x.GUBUN === '2'
											  )[0].CARD_NO
											: ''
									)
								}}
							</dd>
						</dl>
						<dl v-if="order.MONTH_AMT > 0 && canceledOrderChk(i)">
							<dt>정기결제</dt>
							<dd>
								{{ order.MONTH_AMT | comma }} 원/월<br />{{ order.CARDCOM_NM }}
								{{ order.CARD_NO ? getMaskedAccNo(order.CARD_NO) : '' }}
							</dd>
						</dl>

						<dl v-if="canceledOrderChk(i)">
							<dt>설치주소</dt>
							<dd>{{ order.CITY1 }} {{ order.STREET }}</dd>
						</dl>
						<dl
							v-if="
								['109', '111', '112', '113'].includes(
									options.stat.statusCode
								) && canceledOrderChk(i)
							"
						>
							<dt>서명완료</dt>
							<dd>{{ order.E_SIGN_DT }} {{ order.E_SIGN_TM }}</dd>
						</dl>
						<dl v-if="canceledOrderChk(i)">
							<dt>설치일시</dt>
							<dd>
								<p
									class="ft-blue"
									v-if="
										['112', '113'].includes(options.stat.statusCode) &&
											order.E_ASSIGN.TYPE === ''
									"
								>
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? order.E_YYYY_MM + ' 배정 진행 가능'
											: '택배 배송'
									}}
								</p>
								<p
									:class="{
										'ft-orange': ['111', '113'].includes(
											options.stat.statusCode
										)
									}"
									v-else-if="
										deliveryInfos.length === orderList.length &&
											deliveryInfos[i].workerType === 'EXPRESS'
									"
								>
									택배 배송
								</p>
								<p
									:class="{
										'ft-blue': ['111', '113'].includes(options.stat.statusCode)
									}"
									v-else-if="order.E_ASSIGN.TYPE === 'T'"
								>
									{{
										dateToBookingText({
											bookingDate: order.E_ASSIGN.PLAN_DT,
											bookingSlot: order.E_ASSIGN.PLAN_TM_TP,
											bookingSlotTime: order.E_ASSIGN.PLAN_TM_ITV
										})
									}}
								</p>
								<p
									:class="{
										'ft-orange': ['111', '113'].includes(
											options.stat.statusCode
										)
									}"
									v-else
								>
									{{
										order.E_ASSIGN.CODE === 'NAE'
											? '배정실패 (사업자 등록증 미승인)'
											: '배정실패 (선택한 배정구간 사용불가)'
									}}
								</p>
							</dd>
						</dl>
						<dl v-if="canceledOrderChk(i) === false">
							<dt>주문상태</dt>
							<dd>주문삭제</dd>
						</dl>
					</div>
				</div>
			</template>
			<div class="bottom_box">
				<v-btn color="primary" class="col_1_small" @click="ok()">
					{{
						options.stat && ['111', '113'].includes(options.stat.statusCode)
							? '주문 이어서 하기'
							: '계약서 재발송'
					}}
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import filters from '@/mixins/filters'
import { fetchEContract } from '@/apis/order.ec.api'
import {
	checkingStatus,
	fetchWebOrderInfoById,
	postWebOrderInfoById
} from '@/apis/order.status.api'
import {
	getPhone,
	getPrdName,
	getMaskedAccNo,
	getMessageByStatusCode
} from '@/utils/ec'
import { dateToBookingText } from '@/utils/schedule.js'
export default {
	name: 'OrderStatusReSendDialog',
	mixins: [filters],
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: false,
			cancelOrder: false,
			stat: null
		},
		selected: [],
		orderList: [],
		orderStatusList: [],
		webOrderSum: {},
		products: [],
		deliveryInfos: Array
	}),
	methods: {
		getPhone,
		getPrdName,
		getMaskedAccNo,
		dateToBookingText,
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		async open(options) {
			this.$log('options : ', options)
			Object.assign(this.options, options)
			this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']

			await this.createOrderList()
			await this.createOrderStatusList()
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				this.dialog = false
				this.$emit('reload')
				return
			}

			this.dialog = true

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		canceledOrderChk(i) {
			if (
				this.orderList.length === this.orderStatusList.length &&
				this.orderStatusList[i].ORDER_STATUS === '203'
			) {
				return false
			} else {
				return true
			}
		},
		getCancelOrdPrdNm(name) {
			let prdNm = ''
			this.products = this.$store.getters['pay/payProducts']
			this.products.forEach(v => {
				if (v.prd.prod_nm === name) {
					prdNm = v.options.prdha
				}
			})
			return prdNm
		},

		async ok() {
			await this.createOrderStatusList()
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				this.dialog = false
				this.$emit('reload')
				return
			}
			//region 배정실패
			if (['111', '113'].includes(this.options.stat.statusCode)) {
				let checkingOrderStatus

				//region 주문취소 확인
				checkingOrderStatus = await this.orderStatusList.some(
					v => v.ORDER_STATUS !== '203'
				)

				if (!checkingOrderStatus) {
					await this.saveWebOrderInfo('203')
					this.dialog = false
					this.$emit('reload')
					return
				}
				//endregion

				//region 주문확정 확인
				checkingOrderStatus = await this.orderList.some(
					(order, index) =>
						order.E_ASSIGN.TYPE !== 'T' &&
						this.orderStatusList[index].ORDER_STATUS !== '203' &&
						this.deliveryInfos[index].workerType !== 'EXPRESS'
				)

				if (!checkingOrderStatus) {
					await this.saveWebOrderInfo('109')
					this.dialog = false
					this.$emit('reload')
					return
				}
				//endregion

				this.dialog = false
				this.resolve(true)
			} else {
				this.dialog = false
				this.resolve(true)
			}
			//endregion
		},
		async createOrderList() {
			const res = await fetchEContract({
				mobOrderNo: this.$store.getters['verify/mobOrderNo']
			})

			this.orderList = res.resultObject.data[0].setup

			if (['111', '113'].includes(this.options.stat.statusCode)) {
				this.orderList.forEach((v, i) => {
					const deliveryInfo = this.deliveryInfos[i]

					if (deliveryInfo.workerType === 'EXPRESS') {
						return
					}

					if (v.E_ASSIGN.TYPE === 'T') {
						deliveryInfo.bookingDate = v.E_ASSIGN.PLAN_DT.replaceAll('-', '')
						deliveryInfo.bookingSlot = v.E_ASSIGN.PLAN_TM_TP
						deliveryInfo.bookingSlotTime = v.E_ASSIGN.PLAN_TM_ITV
						deliveryInfo.bookingYn = true
					}
				})
			}
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const res = await fetchWebOrderInfoById(mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(mobOrderNo, webParams)

				const msg = getMessageByStatusCode('203')
				await this.showSnackbar({
					msg: msg,
					color: '#222'
				})
			}

			return cancelYn
		},
		async saveWebOrderInfo(statusCode) {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			const res = await fetchWebOrderInfoById(mobOrderNo)
			const webParams = res.resultObject
			webParams.statusCode = statusCode

			await postWebOrderInfoById(mobOrderNo, webParams)

			let msg

			if (statusCode === '109') {
				webParams.step = '07'
				msg =
					'영업정보 시스템을 통해 배정이 모두 완료되어, 주문확정 되었습니다.'
			} else if (statusCode === '203') {
				webParams.step = '10'
				msg = '영업정보 시스템을 통해 주문이 삭제되었습니다.'
			}

			if (msg) {
				await this.showSnackbar({
					msg: msg,
					color: '#222'
				})
			}
		},
		// 상품 갯수 카운트
		getProductListCnt(prdList) {
			let result = 0
			prdList.forEach(v => {
				result += Number(v.options.quantity)
			})
			return result - 1
		},
		cancelOrder() {
			this.resolve(false)
			this.dialog = false
		},
		close() {
			this.dialog = false
		}
	}
}
</script>
