var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.options.width,"scrollable":"","persistent":_vm.options.persistent,"fullscreen":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"full_dialog_header"},[_c('h2',{staticClass:"tit"},[_vm._v(_vm._s(_vm.options.statName))]),_c('v-btn',{staticClass:"btn_right",attrs:{"depressed":"","icon":"","plain":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-window-close ")])],1)],1),_c('div',{staticClass:"dialog_all_page pa-6",staticStyle:{"height":"calc(100vh - 50px)"}},[_c('div',{staticClass:"infor_area"},[_c('div',{staticClass:"tag"},[_vm._v(_vm._s(_vm.options.custType))]),(_vm.options.custType === '개인사업자')?_c('h3',{staticClass:"name"},[(_vm.options.stat.info.customer.bizName)?[_vm._v(" "+_vm._s(_vm.options.stat.info.customer.bizName)+" ")]:_c('templete',[_vm._v(" "+_vm._s(_vm.options.stat.info.customer.name)+" ")])],2):_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.options.name))]),_c('div',{staticClass:"flex"},[_c('p',{staticClass:"data"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.options.sellType))]),_c('span',[_vm._v(_vm._s(_vm.options.goodsNm))]),(
							_vm.options.stat !== null &&
								_vm.options.stat.info.prdList &&
								_vm.options.stat.info.prdList &&
								_vm.getProductListCnt(_vm.options.stat.info.prdList) !== 0
						)?[_vm._v(" 외 "+_vm._s(_vm.getProductListCnt(_vm.options.stat.info.prdList))+"건 ")]:_vm._e()],2)]),_c('div',{staticClass:"flex"},[_c('p',{staticClass:"text_gray"},[_vm._v(_vm._s(_vm.options.address))])]),_c('p',{staticClass:"text_gray"},[_vm._v(_vm._s(_vm.getPhone(_vm.options.mob)))])]),_vm._l((_vm.orderList),function(order,i){return [(
					_vm.deliveryInfos &&
						(_vm.options.stat.statusCode !== '111' ||
							_vm.deliveryInfos[i].workerType !== 'EXPRESS')
				)?_c('div',{key:i,staticClass:"bottom_diver"},[_c('div',{staticClass:"wrap_big_check expand"},[_c('span',{staticClass:"add_index"},[_vm._v("주문"),_c('em',[_vm._v(_vm._s(i + 1))]),_vm._v(".")]),_c('span',{class:_vm.canceledOrderChk(i) === false
								? 'add_label ft-orange'
								: 'add_label'},[_vm._v(_vm._s(order.GOODS_NM ? order.GOODS_NM : _vm.deliveryInfos[i].prodNm)+" "+_vm._s(_vm.getPrdName(order.PRODH9) === '기타' ? _vm.getPrdName(_vm.deliveryInfos[i].PRODH9) : _vm.getPrdName(order.PRODH9))+" ")])]),_c('div',{staticClass:"info_list bor_gray py-3 my-3"},[_c('dl',[_c('dt',[_vm._v("주문번호")]),_c('dd',[_vm._v(_vm._s(order.ORDER_NO))])]),_c('dl',[_c('dt',[_vm._v("구매방식")]),_c('dd',[_vm._v(_vm._s(order.E_SALE_GUBUN))])]),(order.GOODS_AMT > 0 && _vm.canceledOrderChk(i))?_c('dl',[_c('dt',[_vm._v("수납정보")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("comma")(order.GOODS_AMT))+" 원"),_c('br'),_vm._v(_vm._s(order.T_A0401_PAYMENT_LIST.filter(function (x) { return x.GUBUN === '2'; })[0] ? order.T_A0401_PAYMENT_LIST.filter(function (x) { return x.GUBUN === '2'; })[0] .CARDCOM_NM : '')+" "+_vm._s(_vm.getMaskedAccNo( order.T_A0401_PAYMENT_LIST.filter(function (x) { return x.GUBUN === '2'; })[0] ? order.T_A0401_PAYMENT_LIST.filter( function (x) { return x.GUBUN === '2'; } )[0].CARD_NO : '' ))+" ")])]):_vm._e(),(order.MONTH_AMT > 0 && _vm.canceledOrderChk(i))?_c('dl',[_c('dt',[_vm._v("정기결제")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("comma")(order.MONTH_AMT))+" 원/월"),_c('br'),_vm._v(_vm._s(order.CARDCOM_NM)+" "+_vm._s(order.CARD_NO ? _vm.getMaskedAccNo(order.CARD_NO) : '')+" ")])]):_vm._e(),(_vm.canceledOrderChk(i))?_c('dl',[_c('dt',[_vm._v("설치주소")]),_c('dd',[_vm._v(_vm._s(order.CITY1)+" "+_vm._s(order.STREET))])]):_vm._e(),(
							['109', '111', '112', '113'].includes(
								_vm.options.stat.statusCode
							) && _vm.canceledOrderChk(i)
						)?_c('dl',[_c('dt',[_vm._v("서명완료")]),_c('dd',[_vm._v(_vm._s(order.E_SIGN_DT)+" "+_vm._s(order.E_SIGN_TM))])]):_vm._e(),(_vm.canceledOrderChk(i))?_c('dl',[_c('dt',[_vm._v("설치일시")]),_c('dd',[(
									['112', '113'].includes(_vm.options.stat.statusCode) &&
										order.E_ASSIGN.TYPE === ''
								)?_c('p',{staticClass:"ft-blue"},[_vm._v(" "+_vm._s(_vm.deliveryInfos[i].workerType !== 'EXPRESS' ? order.E_YYYY_MM + ' 배정 진행 가능' : '택배 배송')+" ")]):(
									_vm.deliveryInfos.length === _vm.orderList.length &&
										_vm.deliveryInfos[i].workerType === 'EXPRESS'
								)?_c('p',{class:{
									'ft-orange': ['111', '113'].includes(
										_vm.options.stat.statusCode
									)
								}},[_vm._v(" 택배 배송 ")]):(order.E_ASSIGN.TYPE === 'T')?_c('p',{class:{
									'ft-blue': ['111', '113'].includes(_vm.options.stat.statusCode)
								}},[_vm._v(" "+_vm._s(_vm.dateToBookingText({ bookingDate: order.E_ASSIGN.PLAN_DT, bookingSlot: order.E_ASSIGN.PLAN_TM_TP, bookingSlotTime: order.E_ASSIGN.PLAN_TM_ITV }))+" ")]):_c('p',{class:{
									'ft-orange': ['111', '113'].includes(
										_vm.options.stat.statusCode
									)
								}},[_vm._v(" "+_vm._s(order.E_ASSIGN.CODE === 'NAE' ? '배정실패 (사업자 등록증 미승인)' : '배정실패 (선택한 배정구간 사용불가)')+" ")])])]):_vm._e(),(_vm.canceledOrderChk(i) === false)?_c('dl',[_c('dt',[_vm._v("주문상태")]),_c('dd',[_vm._v("주문삭제")])]):_vm._e()])]):_vm._e()]}),_c('div',{staticClass:"bottom_box"},[_c('v-btn',{staticClass:"col_1_small",attrs:{"color":"primary"},on:{"click":function($event){return _vm.ok()}}},[_vm._v(" "+_vm._s(_vm.options.stat && ['111', '113'].includes(_vm.options.stat.statusCode) ? '주문 이어서 하기' : '계약서 재발송')+" ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }