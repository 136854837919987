<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
		fullscreen
	>
		<div class="full_dialog_header">
			<h2 class="tit">{{ options.statName }}</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<div class="dialog_all_page pa-6" style="height: calc(100vh - 50px);">
			<div class="infor_area">
				<div class="tag">{{ options.custType }}</div>
				<h3 class="name">{{ options.name }}</h3>
				<!--// 개인사업자 인증전 : 고객명, 인증 후 사업자명 -->
				<div class="flex">
					<p
						class="data"
						v-if="options.stat !== null && options.stat.info.prdList"
					>
						<span class="primary--text">{{ options.sellType }}</span>
						<span>{{ options.goodsNm }}</span>
						<template v-if="getProductListCnt(options.stat.info.prdList) !== 0">
							외 {{ getProductListCnt(options.stat.info.prdList) }}건
						</template>
					</p>
				</div>
				<div class="flex">
					<p class="text_gray">{{ options.address }}</p>
				</div>
				<p class="text_gray">{{ getPhone(options.mob) }}</p>
			</div>
			<div class="message_box mt-3">
				<span class="txt_body1">
					<div v-html="options.diffCust"></div>
				</span>
			</div>
			<div class="bottom_box">
				<v-btn color="primary" class="col_1_small" @click="ok">
					확인
				</v-btn>
				<v-btn
					depressed
					height="20"
					class="btn_underline my-6"
					@click="cancelOrder"
					v-if="options.statName !== '주문삭제'"
				>
					주문삭제
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'
import { getPhone } from '@/utils/ec'
import {
	fetchWebOrderInfoById,
	postWebOrderInfoById
} from '@/apis/order.status.api'

export default {
	name: 'OrderStatusDefaultDialog',
	mixins: [filters],
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: false,
			cancelOrder: false,
			stat: null
		}
	}),
	methods: {
		getPhone,
		open(options) {
			this.dialog = true
			this.options = Object.assign(this.options, options)
			this.$log(JSON.stringify(this.options))
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		// 상품 갯수 카운트
		getProductListCnt(prdList) {
			let result = 0
			prdList.forEach(v => {
				result += Number(v.options.quantity)
			})
			return result - 1
		},
		ok() {
			this.resolve(true)
			this.dialog = false
		},
		async cancelOrder() {
			const res = await this.$alert({
				message:
					'주문을 삭제하면 재진행은 불가합니다. 주문을 삭제 하시겠습니까?',
				okText: '예',
				cancelText: '아니오'
			})
			if (res) {
				await this.saveWebOrderInfo('203')
				this.resolve(false)
				this.dialog = false
				this.$emit('reload')
			}
		},
		close() {
			this.dialog = false
		},
		async saveWebOrderInfo(statusCode) {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			const res = await fetchWebOrderInfoById(mobOrderNo)
			const webParams = res.resultObject
			webParams.statusCode = statusCode

			await postWebOrderInfoById(mobOrderNo, webParams)
		}
	}
}
</script>
